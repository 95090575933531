<template>
  <div class="message-files">
    <div class="message-files__title" v-if="showHeader">
      <h5>
        Anexos
        <span class="message-files--count">
          {{ files.filter(x => x.visible).length }}
        </span>
      </h5>
      <small
        class="message-files__title--clean"
        @click="cleanFilter"
        v-if="showFilter"
      >
        Limpar filtro
      </small>
    </div>

    <vue-perfect-scrollbar
      class="message-files--scroll"
      :settings="scrollSettings"
    >
      <ul class="message-files-list">
        <template v-for="file in files">
          <li :key="file.id" :id="`file-${file.id}`" v-if="file.visible">
            <div class="message-files-list__icon">
              <span
                :class="[
                  'message-files-list__icon--type',
                  getFileColor(file.contentType)
                ]"
              >
                <i :class="getFileIcon(file.contentType)" />
              </span>
            </div>

            <div class="message-files-list__content">
              <div class="message-files-list__title" :title="file.fileName">
                {{ file.fileName }}
              </div>
              <div class="message-files-list__subtitle">
                {{ formatDate(file.createdAt) }}
              </div>
            </div>

            <div class="message-files-list__icon">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span
                    :id="`btn-download-${file.id}`"
                    :name="`btn-download-${file.id}`"
                    class="message-files-list__icon--download"
                    v-on="on"
                    @click="download(file.id)"
                  >
                    <i class="fa-solid fa-download"></i>
                  </span>
                </template>
                <span>Baixar arquivo</span>
              </v-tooltip>
            </div>
          </li>
        </template>
      </ul>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getFileIcon, getFileColor } from '@/helpers/utils';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { SupportService } from '@/services/api/support';

export default {
  components: {
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar')
  },

  props: {
    showHeader: {
      type: Boolean,
      default: true,
      require: false
    },
    ticketId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    scrollSettings: { maxScrollbarLength: 50, suppressScrollX: true }
  }),

  computed: {
    ...mapGetters({
      files: 'support/getFiles',
      showFilter: 'support/getShowCleanFilter'
    })
  },

  methods: {
    formatDate,
    getFileIcon,
    getFileColor,

    async download(fileId) {
      try {
        const supportService = new SupportService();
        const { status, data } = await supportService.downloadFiles({
          ticketId: this.ticketId,
          fileId
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    cleanFilter() {
      this.$store.commit('support/filteredFiles', null);
    }
  }
};
</script>
